import React, { useState, useEffect } from 'react'
import { Input, Modal, Form, Col, Row, Select, Switch, Spin, message } from 'antd'
import { getCustomerOneNew, getCustomerListNew, platformUserList } from '@/service/customerService'
import { getOrganCurrentUser } from '@/service/orgService'
import { getTeamList } from '@/service/teamService'
import { orderEditCustomerUser } from '@/service/ordersService'
import { getPcodeDict } from '@/utils/dataStorage'
import debounce from 'lodash/debounce'
import { organPage } from '@/service/orgService'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { Option } = Select

// 客户类型常量
const CUSTOMER_TYPES = {
    SPECIAL: '017008',
    NORMAL: '017001',
}

// 样式配置
const styles = {
    container: {
        background: '#fff',
        padding: '12px 16px',
        marginBottom: '16px',
        border: '1px solid #d9d9d9',
        borderRadius: '6px',
    },
    labelText: {
        color: '#666',
        marginRight: '8px',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    valueText: {
        color: '#333',
        fontSize: '14px',
    },
    warningBox: {
        marginTop: '12px',
        padding: '12px 16px',
        background: '#fffbe6',
        border: '1px solid #ffe58f',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
    },
    warningIcon: {
        color: '#faad14',
        marginRight: '8px',
        fontSize: '16px',
    },
    warningTitle: {
        color: '#d46b08',
        fontWeight: 'bold',
        marginBottom: '4px',
    },
    warningContent: {
        color: '#666',
    },
    modeText: {
        fontSize: '14px',
        color: '#666',
        marginBottom: '12px',
    },
    switchModeStyle: {
        padding: '12px 24px',
        background: '#f5f5f5',
        borderRadius: '6px',
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contentStyle: {
        padding: '16px',
        background: '#fff',
        borderRadius: '6px',
        border: '1px solid',
        borderColor: '#d9d9d9',
        transition: 'all 0.3s',
    },
    infoStyle: {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '16px',
        background: '#fafafa',
        borderRadius: '6px',
        marginTop: '16px',
    },
    customerInfoDisplayStyle: {
        background: '#fff',
        padding: '12px 16px',
        marginBottom: '16px',
        border: '1px solid #d9d9d9',
        borderRadius: '6px',
    },
    labelStyle: {
        color: '#666',
        marginRight: '8px',
        fontSize: '14px',
    },
    valueStyle: {
        color: '#333',
        fontSize: '14px',
    },
}

const OrderCustomerEdit = (props) => {
    const { visible, onCancel, orderDetailsInfo, orderVehicleInfo, handleSuccess } = props
    const [organList, setOrganList] = useState({ data: [], fetching: false })
    const [form] = Form.useForm()
    const [teamData, setTeamData] = useState({
        //团队信息
        data: [],
        fetching: false,
    })
    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        data: [],
        fetching: false,
    })

    //所属经理
    const [businessManager, setBusinessManager] = useState({
        data: [],
        fetching: false,
    })
    const [names, setNames] = useState('')
    const [isCustomerSwitching, setIsCustomerSwitching] = useState(false)
    const [originCustomerInfo, setOriginCustomerInfo] = useState({})

    useEffect(() => {
        resetFormItem()
        setOriginCustomerInfo({
            customerName: orderDetailsInfo.customerName,
            customerCode: orderDetailsInfo.customerCode,
            customerCorporation: orderDetailsInfo.customerCorporation,
            customerCorporationPhone: orderDetailsInfo.customerCorporationPhone,
        }) //原始客户信息
    }, [orderDetailsInfo]) // 添加依赖项

    // 设置默认业务经理
    const setDefultbusinessManager = async () => {
        const data = await getOrganCurrentUser()
        const { defBusinessManager, defBusinessManagerName } = data.retData
        if (defBusinessManager) {
            return { text: defBusinessManagerName, value: defBusinessManager }
        } else {
            return null
        }
    }

    //回显表单
    const resetFormItem = async (handleChangeCustomerId = '') => {
        let defaultbusiness = await setDefultbusinessManager()
        const res = await getCustomerOneNew(handleChangeCustomerId ? handleChangeCustomerId : orderDetailsInfo.customerId)
        if (!res || !res.retData) {
            message.error('获取客户信息失败')
            return
        }

        let retData = res.retData
        let { businessManager } = retData
        if (!businessManager) {
            setBusinessManager({
                data: defaultbusiness ? [defaultbusiness] : [],
                fetching: false,
            })
            businessManager = defaultbusiness ? defaultbusiness.value : null
        } else {
            setBusinessManager({
                data: [{ text: retData.businessManagerName, value: retData.businessManager }],
                fetching: false,
            })
        }
        form.setFieldsValue({
            ...retData,
            businessManager,
            customerId: retData.id,
            customerStatus: retData.customerStatus == 0 ? true : false,
            customerType: retData.customerType === CUSTOMER_TYPES.SPECIAL ? CUSTOMER_TYPES.NORMAL : retData.customerType,
        })
        setTeamData({
            data: [{ text: retData.teamName, value: retData.teamId }],
            fetching: false,
        }) //团队列表回显
        setCustomerInfo({
            data: [{ text: retData.customerName, value: retData.id }],
            fetching: false,
        })
        //客户列表回显
        setNames(retData.customerName)
        setOrganList({
            data: [
                {
                    text: retData.organName,
                    value: retData.organId,
                },
            ],
            fetching: false,
        })
        // }
    }
    //团队派单信息查询
    const fetchTeam = (value) => {
        setTeamData({
            data: [],
            fetching: true,
        })
        let data = {
            teamName: value ? value : null,
            searchItem: {},
            pageSize: 50,
            pageNum: 1,
        }
        getTeamList(data).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.teamName,
                value: team.id,
            }))
            setTeamData({
                data: dataMap,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    const fetchTeams = debounce(fetchTeam, 500) // 减少延迟时间

    const handleChangeTeam = (value, option) => {
        form.setFieldsValue({
            teamName: option.children,
        })
    }
    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 20,
            currentPage: 1,
            searchItem: {
                queryTemporaryCustomer: 1,
                customerName: value,
            },
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                if (item.customerStatus === 0) {
                    arr.push({
                        text: item.customerName,
                        value: item.id,
                    })
                }
                return arr
            }, [])
            setCustomerInfo({
                data: data,
                fetching: false,
            })
            // }
        })
    }

    //每次选取获取客户信息
    const handleChangeName = (value) => {
        setIsCustomerSwitching(true)
        value && resetFormItem(value)
    }
    //保存
    const saveEditCustomer = async () => {
        const value = await form.validateFields()
        let data = {
            ...value,
            orderId: orderDetailsInfo.id,
            customerStatus: value.customerStatus ? 0 : 1,
            customerVehicleId: orderVehicleInfo.key,
            customerUserId: orderDetailsInfo.customerUserId,
        }

        const res = await orderEditCustomerUser(data)
        if (!res) {
            throw new Error('保存失败')
        }

        handleSuccess({
            customerName: data.customerName,
            customerId: data.customerId,
            customerType: data.customerType,
        })
        onCancel(false)
        message.success('该工单的客户修改完成')
        setIsCustomerSwitching(false)
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)
    //查询机构
    const organQuery = (value) => {
        setOrganList({
            data: [],
            fetching: true,
        })
        let data = {
            searchItem: { organName: value, orderFlag: 0 },
            pageNum: 1,
            pageSize: 10,
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }
        organPage(data).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.organName,
                    value: item.id,
                }))
                setOrganList({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning('没有查询到机构')
            }
            // }
        })
        // .catch((err) => {
        //     if (err) {
        //         message.warning("没有查询到机构")
        //     }
        // })
    }
    //防抖
    const organQuerys = debounce(organQuery, 600)
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleChangeOrgan = (value, option) => {
        if (option) {
            form.setFieldsValue({
                organId: value,
                organName: option.children,
            })
        }
    }

    const businessManagerQuery = (value, key) => {
        setBusinessManager({
            ...businessManager,
            fetching: true,
        })

        platformUserList({
            searchItem: {
                nickname: value,
            },
            pageNum: 1,
            pageSize: 15,
        }).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    arr.push({
                        text: item.nickname,
                        value: item.id,
                    })
                    return arr
                }, [])
                setBusinessManager({
                    data: data,
                    fetching: false,
                })
            } else {
                console.log('没有查询到')
            }
        })
    }
    //防抖
    const businessManagerQuerys = debounce(businessManagerQuery, 1000)

    return (
        <Modal
            visible={visible}
            title={isCustomerSwitching ? '切换客户' : '编辑客户信息'}
            centered={true}
            onOk={() => saveEditCustomer()}
            onCancel={() => {
                setIsCustomerSwitching(false)
                onCancel(false)
            }}
            width={1260}
        >
            <div>
                <div style={styles.customerInfoDisplayStyle}>
                    <Row gutter={24} align="middle">
                        <Col span={3}>
                            <span style={styles.labelText}>原客户信息：</span>
                        </Col>
                        <Col span={5}>
                            <span style={styles.labelText}>客户名称：</span>
                            <span style={styles.valueText}>{originCustomerInfo.customerName || '-'}</span>
                        </Col>
                        <Col span={5}>
                            <span style={styles.labelText}>客户编码：</span>
                            <span style={styles.valueText}>{originCustomerInfo.customerCode || '-'}</span>
                        </Col>
                        <Col span={5}>
                            <span style={styles.labelText}>联系人：</span>
                            <span style={styles.valueText}>{originCustomerInfo.customerCorporation || '-'}</span>
                        </Col>
                        <Col span={5}>
                            <span style={styles.labelText}>联系电话：</span>
                            <span style={styles.valueText}>{originCustomerInfo.customerCorporationPhone || '-'}</span>
                        </Col>
                    </Row>
                </div>
                <div style={styles.switchModeStyle}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px', fontSize: '14px', fontWeight: 'bold' }}>操作模式：</span>
                        <Switch
                            checked={isCustomerSwitching}
                            onChange={(checked) => {
                                setIsCustomerSwitching(checked)
                                if (!checked) {
                                    form.resetFields(['customerId'])
                                    setCustomerInfo({ data: [], fetching: false })
                                }
                            }}
                            checkedChildren="切换客户"
                            unCheckedChildren="编辑信息"
                        />
                    </div>
                    <div style={{ color: '#666' }}>
                        {isCustomerSwitching ? '当前模式：切换客户 - 仅允许选择新客户' : '当前模式：编辑信息 - 可修改客户详细信息'}
                    </div>
                    {isCustomerSwitching && (
                        <div style={styles.warningBox}>
                            <ExclamationCircleOutlined style={styles.warningIcon} />
                            <div>
                                <div style={styles.warningTitle}>切换客户提醒</div>
                                <div style={styles.warningContent}>
                                    选择新客户后，当前订单的用户信息和车辆信息将会迁移到新客户名下。此操作不可逆，请谨慎操作。
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div style={styles.contentStyle}>
                    <Form form={form} {...formItemLayout}>
                        <Form.Item name="customerId" hidden={true}>
                            <Input />
                        </Form.Item>
                        <Row gutter={24}>
                            {isCustomerSwitching && (
                                <Col span={8}>
                                    <Form.Item
                                        label="选择新客户"
                                        name="customerId"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择客户名称',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="请选择客户"
                                            notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onChange={handleChangeName}
                                            onFocus={() => customerQuery()}
                                            onSearch={customerQuerys}
                                            style={{ width: '100%' }}
                                        >
                                            {customerInfo.data.map((item) => (
                                                <Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label="客户名称"
                                    name="customerName"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入客户名称',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入客户名称" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="客户编码" name="customerCode">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="联系人" name="customerCorporation">
                                    <Input placeholder="请输入联系人" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="联系电话" name="customerCorporationPhone">
                                    <Input placeholder="请输入联系电话" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="传真" name="customerFax">
                                    <Input placeholder="请输入传真" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="客户类型"
                                    name="customerType"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择客户类型',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择客户类型" disabled={isCustomerSwitching}>
                                        {getPcodeDict('017').children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="电子邮箱" name="customerEmail">
                                    <Input placeholder="请输入电子邮箱" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="办公电话" name="customerPhone">
                                    <Input placeholder="请输入办公电话" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="邮编" name="customerZipcode">
                                    <Input placeholder="请输入邮编" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="所属机构" name="organId">
                                    <Select
                                        showSearch
                                        placeholder="请选择所属机构"
                                        notFoundContent={organList.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => organQuery('')}
                                        onSearch={organQuerys}
                                        onSelect={handleChangeOrgan}
                                        style={{ width: '100%' }}
                                        disabled={isCustomerSwitching}
                                    >
                                        {organList.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="所属团队" name="teamId">
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="请选择创业团队"
                                        notFoundContent={teamData.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onChange={handleChangeTeam}
                                        onFocus={() => fetchTeam()}
                                        onSearch={fetchTeams}
                                        style={{ width: '100%' }}
                                        disabled={isCustomerSwitching}
                                    >
                                        {teamData.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="客户状态" name="customerStatus" valuePropName="checked">
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="业务经理" name="businessManager">
                                    <Select
                                        showSearch
                                        placeholder="请选择业务经理"
                                        notFoundContent={businessManager.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => businessManagerQuerys('')}
                                        onSearch={businessManagerQuerys}
                                        style={{ width: '100%' }}
                                        disabled={isCustomerSwitching}
                                    >
                                        {businessManager.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="地址" name="customerAddress" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                    <Input placeholder="请输入联系地址" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="备注" name="customerDesc" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                    <Input placeholder="请输入备注" disabled={isCustomerSwitching} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div style={styles.infoStyle}>
                    <div>用户名：{orderDetailsInfo.customerUserName}</div>
                    <div>
                        车辆牌照：
                        {orderVehicleInfo && orderVehicleInfo.children}
                    </div>
                    <div>电话：{orderDetailsInfo.repairContactPhone}</div>
                </div>
            </div>
        </Modal>
    )
}
export default OrderCustomerEdit
