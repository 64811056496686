import React, { useState, useEffect } from 'react'
import { Modal, Button, Steps, Popover, Tabs, Empty } from 'antd'
import { customerOperaSnapshot } from '@/service/customerService'
import moment from 'moment'
import { OperatorUserType } from '@/utils/enum.js'
import styled from '@emotion/styled'
const { TabPane } = Tabs
//项目
export default ({ logId, setLogId }) => {
    const [logs, setLogs] = useState([])

    useEffect(() => {
        if (logId) {
            loadData()
            console.log(logId)
        } else {
            setLogs([])
        }
    }, [logId])

    const loadData = () => {
        customerOperaSnapshot(logId).then((res) => {
            console.log(res)
            let list = res.retData
            list.sort((a, b) => {
                return moment(b.createTime).unix() - moment(a.createTime).unix()
            })
            let log = list.map((item) => ({
                title: item.describe,
                subTitle: item.createTime,
                description: (
                    <>
                        <div className="operatorUser">{`操作人:${OperatorUserType[item.operatorType] || ''}--${item.operatorName || ''}`}</div>
                        <div className="traceId">{`traceId:${item.traceId}`}</div>
                    </>
                ),
            }))
            setLogs(log)
        })
    }

    const customDot = (dot, { status, index }) => (
        <Popover
            content={
                <span>
                    step {index} status: {status}
                </span>
            }
        >
            {dot}
        </Popover>
    )

    const onCancel = () => {
        setLogId(null)
        setLogs([])
    }

    return (
        <Modal
            open={!!logId}
            width={800}
            onCancel={onCancel}
            footer={[
                <Button onClick={onCancel} key="back">
                    {' '}
                    取消
                </Button>,
            ]}
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="操作日志" key="1">
                    {logs.length === 0 ? (
                        <Empty />
                    ) : (
                        <LogSteps>
                            <Steps className="logSteps" current={0} progressDot={customDot} direction="vertical" items={logs} />
                        </LogSteps>
                    )}
                </TabPane>
            </Tabs>
        </Modal>
    )
}

const LogSteps = styled.div`
    height: 500px;
    overflow-y: scroll;
    .ant-steps-item-title {
        font-size: 20px;
    }
    .ant-steps-item-subtitle {
        font-size: 16px;
    }
    .operatorUser {
        font-size: 16px;
        color: #2e3aeb !important;
    }
`
