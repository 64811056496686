import React from "react";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useStockCheckDetailsModel } from "./stockCheckDetailModel";
import { formatNum, formatInt } from "@/erp_subpackage/utils/util";

export const FixedBottom = ({ modeKey, mode, rowCode, dispatch }) => {
    const { collapsed } = useGlobalModel(({ collapsed }) => [collapsed]);
    const { addDesc, editDesc, lookDesc } = useStockCheckDetailsModel();
    const typeDesc = {
        add: addDesc,
        edit: editDesc,
        look: lookDesc
    }
    const orderStatistics = [
        {
            label: "系统库存",
            value: formatInt(typeDesc[mode].stockAll),
            color: "green",
            status: true
        },
        {
            label: "实际库存",
            value: formatInt(typeDesc[mode].checkAll),
            color: "green",
            status: true
        },
        {
            label: "盘盈项数",
            value: (typeDesc[mode].SurplusNumAll),
            color: "green",
            status: true
        },
        {
            label: "盘亏项数",
            value: (typeDesc[mode].deficitNumAll),
            color: "green",
            status: true
        },
        {
            label: "差异数量",
            value: formatInt(typeDesc[mode].differenceNumAll),
            color: "green",
            status: true
        },
        {
            label: "差异金额",
            value: formatNum(typeDesc[mode].differenceMoneyAll),
            color: "green",
            status: true
        },
        {
            label: "数量差异率",
            value: isNaN(typeDesc[mode].differenceRateNumAll) ? "0%" : formatNum((typeDesc[mode].differenceRateNumAll)) + "%",
            color: "green",
            status: true
        },
        {
            label: "金额差异率",
            value: isNaN(typeDesc[mode].differenceRateAll) ? "0%" : formatNum((typeDesc[mode].differenceRateAll)) + "%",
            color: "green",
            status: true
        }
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${collapsed ? '80px' : '200px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}