import React, { useState, useEffect } from "react";
import { Table, Button, Input, Tooltip, message } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { EditableCell, EditableRow } from "@/erp_subpackage/components/YHEditTable/editTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useGlobalModel, transPgToPagination, defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { formatInt, formatNum } from "@/erp_subpackage/utils/util";
import { useStockCheckDetailsModel } from './stockCheckDetailModel';
import { stockStatisticService } from "@/erp_subpackage/pages/stock/statistic/stockStatisticService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

const initPgCfg = { pageSize: 10, current: 1 };

export const MaterialDialog = ({ modeKey, mode, rowCode, dispatch }) => {
    const { sourceApp, branch } = defaultMdCmParam;
    const { user: { shopId, shopName, shopCode, orgName, orgCode },setMaterialInfo } = useGlobalModel(({ user }) => [user]);
    const { materialDialog: visible, editFormVal, addFormVal, setMaterialDialog, insertMaterial } = useStockCheckDetailsModel(({ materialDialog }) => [materialDialog])
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [selectMaterial, setSelectMaterial] = useState([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [loading, setLoadding] = useState(false);
    const formVal = mode === "edit" ? editFormVal : addFormVal;

    useEffect(() => {
        if (visible) {
            loadData()
        } else {
            setSelectMaterial([]);
        };
    }, [visible])

    const onCancel = () => {
        setMaterialDialog(false);
    };

    //加载商品数据
    const loadData = async (page, searchValue = searchParam) => {
        setLoadding(true);
        try {
            let param = {...searchParam,materialCode:searchParam.materialCode?.trim(),materialName:searchParam.materialName?.trim()}
            setSearchParam(param)
            const searchParams = {
                ...param,
                warehouseCode: formVal.warehouseCode,
                shelfCode: formVal.shelfCode?.toString(),
                shopId, shopName, shopCode, orgName, orgCode, sourceApp, branch,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            }
            let { retData } = await stockStatisticService.pages(searchParams);
            const { records, ...pg } = retData;
            setDataSource(records.map(item => ({
                ...item,
                num: 0,
                averagePurchasePrice: item.averagePurchasePrice !== 0 ? item.averagePurchasePrice : item.purchasePrice,
                amount: item.latestPurPrice * 0,
            })));
            setPgCfg(transPgToPagination(pg));
            setLoadding(false);
        } catch (err) { setLoadding(false) }
    }

    const column = (optype) => [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            align: "center",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>
        }, {
            title: '库存',
            width: 120,
            dataIndex: 'inventoryNum',
        }, {
            title: '单位',
            width: 120,
            dataIndex: 'unitName',
        }, {
            title: '数量',
            width: 120,
            dataIndex: 'num',
            editable: true,
        }, {
            // title: '平均进货价 ',
            title: '最新进价 ',
            width: 120,
            dataIndex: 'averagePurchasePrice',
            editable: true,
        }, {
            title: '最近销售价',
            width: 120,
            dataIndex: 'lateSalePrice',
        }, {
            title: '金额',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '操作',
            width: 60,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text, record) => optype === "add" ? (
                <YhBut
                    type="add"
                    txt="添加"
                    disabled={!!selectMaterial.find(item => item.id === record.id)}
                    click={() => {
                        //添加商品
                        setSelectMaterial(origin => [...origin, record])
                    }}
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => {
                        //删除对应商品
                        setSelectMaterial(origin => origin.filter(item => item.id !== record.id))
                    }}
                />
            )
        }
    ];

    const newSelectColumns = (type) => column(type).map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index,
                rowIndex,
                record,
                rules: col.rules,
                options: col.options,
                editType: col.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: (row) => handleSave(row, type),
            }),
        }
    })

    //内联编辑
    const handleSave = (row, type) => {
        row.num = +formatInt(row.num);
        row.inventoryNum = +formatInt(row.inventoryNum);
        if(Number(row.averagePurchasePrice)<0){
            message.warning('进价不可小于0元！')
            row.averagePurchasePrice = 0;
        }else{
            row.averagePurchasePrice = formatNum(row.averagePurchasePrice);
        }
        row.amount = formatNum(row.num * row.averagePurchasePrice);

        (type === "add" ? setDataSource : setSelectMaterial)(origin => {
            const newDataSource = [...origin];
            const index = newDataSource.findIndex((item) => row.id === item.id);
            const item = newDataSource[index];
            newDataSource.splice(index, 1, { ...item, ...row });
            return newDataSource;
        });
    }

    const rightChildren = <div className="flex">
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            value={searchParam.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchParam({ ...searchParam, materialCode: e.target.value?.trim() }) }}
            placeholder="商品编号"
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            value={searchParam.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchParam({ ...searchParam, materialName: e.target.value }) }}
            placeholder="商品名称"
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Button onClick={() => loadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => {
            loadData(initPgCfg, {});
            setSearchParam({})
        }} shape="round">重置</Button>
    </div>

    return <YHModal
        visible={visible}
        okText="确认添加"
        onOk={() => insertMaterial(mode, selectMaterial)}
        onCancel={onCancel}
        title="库存盘点--选择商品"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选商品列表" rightChildren={rightChildren} style={{ margin: "0" }}>
            <Table
                loading={loading}
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns("add")}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content', y: 210 }}
            />
        </YhBox>
        <YhBox title="已选商品列表" style={{ margin: "5px 0 0" }}>
            <Table
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns("delete")}
                dataSource={selectMaterial}
                pagination={false}
                scroll={{ x: 'max-content', y: 210 }}
            />
        </YhBox>
    </YHModal>
}