import React, { useEffect } from "react";
import { Table, Tooltip, message } from "antd";
import { useStockPoDetailModel } from "../backlogModel";
// import { useRecordGoodsModel } from '@/erp_subpackage/views/public/GoodsRecordsModal/recordGoodsModel';
import { formatNum } from '@/erp_subpackage/utils/util'
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";

export function DataTable() {
    const { setMaterialInfo } = useGlobalModel(() => []);
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
        type, types,
    } = useStockPoDetailModel();
    // const { setGoodsRecordModal } = useRecordGoodsModel(() => []);

    const detailColumns = [
        {
            title: '序号',
            width: 66,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编码',
            width: 180,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 260,
            dataIndex: 'materialName',
        }, {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
        }, {
            title: '仓库名称',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位编号',
            width: 140,
            dataIndex: 'shelfCode',
        }, {
            title: '入库数量',
            width: 100,
            dataIndex: 'stockIoNum',
        }, {
            title: '入库单价',
            width: 100,
            dataIndex: 'costPrice',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '入库金额',
            width: 100,
            dataIndex: 'stockIoAmount',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryQuantity',
        }, {
            title: '占用数量',
            width: 100,
            dataIndex: 'occupyNum',
        }, {
            title: '可用数量',
            width: 100,
            render: (text, record) => +(record?.inventoryQuantity ?? 0) - +(record?.occupyNum ?? 0)
        }, {
            title: '库存成本',
            width: 100,
            dataIndex: 'inventoryAmount',
        }, {
            title: '单位名称',
            width: 300,
            dataIndex: 'orgName',
        }, {
            title: '单据编号',
            width: 210,
            dataIndex: 'bussCode',
        }, {
            title: '入库日期',
            width: 160,
            dataIndex: 'stockIoTime',
        }, {
            title: '积压时长',
            width: 160,
            dataIndex: 'overstock',
            render: (text) => text + '天'
        }, {
            title: '最近一月是否销售',
            width: 160,
            dataIndex: 'saleRecent',
            render: (text) => text ? '是' : '否'
        },
    ];
    const allColumns = [
        {
            title: '序号',
            width: 66,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编码',
            width: 180,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 260,
            dataIndex: 'materialName',
        }, {
            title: '替换编号',
            width: 180,
            dataIndex: 'replaceCode',
            render: text => <p className="ellipsis" title={text}>{text}</p>
        },
        {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        },
        // {
        //     title: '期初数量',
        //     width: 100,
        //     dataIndex: 'startNum',
        // },
        {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryQuantity',
        }, {
            title: '占用库存',
            width: 80,
            dataIndex: 'occupyNum',
            // render: (text: any, record: ISCMBacklogAllDetail) => <span
            //     style={{ cursor: "pointer", color: "#00f" }}
            //     onClick={(e) => {
            //         // console.log(record);
            //         e.stopPropagation();
            //         if (text && +text > 0) {
            //             setStockQueryModal(record);
            //             return;
            //         }
            //         message.warning("没有占用库存");
            //     }}>
            //     {text}</span >
        }, {
            title: '可用库存',
            width: 80,
            render: (text, record) => +(record?.inventoryQuantity ?? 0) - +(record?.occupyNum ?? 0)
        }, {
            title: '最新进价',
            width: 100,
            dataIndex: 'latestPurPrice',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '库存成本',
            width: 100,
            dataIndex: 'inventoryAmount',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '积压时长',
            width: 160,
            dataIndex: 'overstock',
            render: (text) => text + '天'
        }, {
            title: '最近一月是否销售',
            width: 160,
            dataIndex: 'saleRecent',
            render: (text) => text ? '是' : '否'
        },
    ];
    useEffect(() => {
        loadData({ pageSize: 15, current: 1 })
    }, [])

    //点击行选高亮
    const onRow = (id) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            rowKey={(record, i) => record.id || record.materialCode + i}
            // rowSelection={{
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            //     selectedRowKeys: selectedRowKeys
            // }}
            columns={type === types.all ? allColumns : detailColumns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id]);
                }
            })}
            scroll={{ x: 'max-content', y: "calc(100vh - 350px)" }}
        />
    )
}
