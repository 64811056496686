import React, { useContext, useState, useEffect } from 'react'
import { Button, Dropdown, Input, Menu, Tooltip } from "antd";
import ListComponent from '@/components/listComponent'
import { printFeieyun, editReturnVisitStatus } from '@/service/ordersService'
import TopSearch from '@/components/TopSearch'
import { getCustomerListNew } from '@/service/customerService'
import ParintMadal from './printOrder' //打印弹框
import { getPcodeDict } from '@/utils/dataStorage'
import { MyContext } from '@/store/myContext'
import VehihcleInfoMadal from './vehihcleInfoMadal' //车管所同步弹框
import ReviewRecord from './orderReviewRecord' //审核记录弹框
import SettleScaniaPrints from './settleScaniaPrints' //打印弹框
import Sanbao from './sanbao'
import OrderLogs from './orderLogs'
import { getAllTable } from './table'
import SetTableSort from '@/components/SetTableSort/index'
import { useOrderTableChange, useInit, useOrderTable, useOrderCodeClick, orderHandle } from './orderHooks'
import { handlePrint, exportOrderDetailed, exportOrder, butResourceApply,snkyPrint, reviewRecord,handleSettleScaniaPrint } from './orderListCommon'
import SknyPrints from './sknyPrints' //打印弹框
import { DownOutlined } from "@ant-design/icons";
import ViewReport from './viewReport';

const saveKey = 'saveCompleteOrderTableSort'
//已完成工单
const OrdersManage = () => {
    const { dispatch, state, message, detailsPage, Option, showAllOrgan } = useContext(MyContext)
    const [showReturnVisitVisible, setShowReturnVisitVisible] = useState(false)

    const [showSetTableSort, setShowSetTableSort] = useState(false)

    const [showSanbao, setShowSanbao] = useState(false)
    const [choosedOrderId, setChoosedOrderId] = useState('')

    const [showLogs, setShowLogs] = useState(false)

    //修改订单回访状态
    const returnVisitStatusSweitchChange = (checked, e, id) => {
        setShowReturnVisitVisible(true)
        e.stopPropagation()
        editReturnVisitStatus({ orderId: id, returnVisitStatus: checked ? 1 : 0 }).then((res) => {
            message.success('操作成功')
            setShowReturnVisitVisible(false)
            // loadData(pagination.current, searchValue)
        })
    }

    const [printVisible, setPrintVisible] = useState({
        visible: false,
        orderId: '',
    }) //打印
    //打印
    const [printSettleScaniaVisible, setPrintSettleScaniaVisible] = useState({
        visible: false,
        orderId: '',
    })
    const [printSnkyVisible, setPrintSnkyVisible] = useState({
        visible: false,
        orderId: '',
    })
    //table 数据
    const [data, selectInfo, setSelectInfo, pagination, loading, loadData, searchValue, setSearchValue, onTableChange] = useOrderTable(state)
    //副标题计算
    const [subTitle, rowSelection] = useOrderTableChange(data, setSelectInfo)
    //初始化数据
    const handleClickOrderCode = (record) => {
        setSelectInfo({ rowInfo: record, rowIndex: data.find((item) => item.id === record.id) })
        setIsOrderCodeClick(true)
    }
    const [dictObj, org, allOrg, columns, initTableColumns] = useInit({
        setShowSanbao,
        setChoosedOrderId,
        setShowLogs,
        handleClickOrderCode,
        saveKey,
        returnVisitStatusSweitchChange,
    })
    //点击订单编号
    const [setIsOrderCodeClick] = useOrderCodeClick(state, { selectInfo, dispatch, detailsPage, setPrintSettleScaniaVisible,setPrintSnkyVisible, setPrintVisible, dictObj })

    useEffect(() => {
        if (state.activeMenu === 'completeOrder' && !showSanbao && !showReturnVisitVisible) {
            loadData(pagination.current, searchValue)
        }
    }, [state, showSanbao, showReturnVisitVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    //打印飞蛾小票
    const handlePrintSmallTicket = (type) => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要打印的工单')
            return
        }
        printFeieyun({ orderId: selectInfo.rowInfo.id, qrCodeType: type }).then((res) => {
            message.success('打印成功')
        })
    }

    const [bjsgl, setBjsgl] = useState({ visible: false }) //公安网同步
    const handleBjsgl = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要同步的工单')
            return
        }
        if (selectInfo.rowInfo.orderStatus !== 'completed') {
            message.warning('只能同步已完成的单子')
            return
        }
        if (selectInfo.rowInfo.bjysglSyncFlag === 'synced') {
            message.warning('该工单信息采集已同步')
            return
        }
        setBjsgl({ visible: true })
    }

    //查看报告
    const [viewReportInfo, setViewReportInfo] = useState({
        visible: false,
        orderId: '',
    })

    const openViewReport = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要查看报告的工单')
            return
        }
        setViewReportInfo({visible: true, orderId: selectInfo.rowInfo.id,})
    }

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: 'searchSelect',
                placeholder: '请选择客户名称',
                valueName: 'customerId',
                optionName: 'customerName',
                optionId: 'id',
                searchApi: getCustomerListNew,
                searchItemName: 'customerName'
            },
            {
                type: 'input',
                placeholder: '请输入客户名称',
                valueName: 'customerName',
            },
            {
                type: 'input',
                placeholder: '请输入工单编号',
                valueName: 'orderCode',
            },
            {
                type: 'input',
                placeholder: '请输入团队名称',
                valueName: 'teamName',
            },
            {
                type: 'input',
                placeholder: '请输入车辆牌照',
                valueName: 'customerVehicleCode',
            },
            {
                type: 'input',
                placeholder: '请输入车辆Vin',
                valueName: 'customerVehicleVin',
            },
            {
                hidden: !showAllOrgan,
                type: 'select',
                placeholder: '请选择客户所属机构',
                valueName: 'organId',
                optionList: () =>
                    allOrg.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择工单类型',
                valueName: 'orderType',
                optionList: () =>
                    getPcodeDict('014').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择故障分类',
                valueName: 'faultClassification',
                optionList: () =>
                    getPcodeDict('037').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'input',
                placeholder: '请输入主修人',
                valueName: 'majorUserName',
            },
        ],
        hideList: [
            {
                type: 'select',
                placeholder: '请选择三包品牌',
                valueName: 'threeGuaranteesBrand',
                optionList: () =>
                    getPcodeDict('106').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择工单来源',
                valueName: 'orderSource',
                optionList: () =>
                    getPcodeDict('008').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择服务类型',
                valueName: 'serviceType',
                optionList: () =>
                    getPcodeDict('011').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择服务方式',
                valueName: 'serviceMethod',
                optionList: () =>
                    getPcodeDict('012').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'input',
                placeholder: '请输入对接人',
                valueName: 'dockPeople',
            },
            {
                type: 'input',
                placeholder: '请输入报修用户',
                valueName: 'repairContactName',
            },
            {
                type: 'input',
                placeholder: '请输入联系电话',
                valueName: 'repairContactPhone',
            },
            {
                type: 'input',
                placeholder: '请输入开单员',
                valueName: 'billName',
            },
            {
                type: 'input',
                placeholder: '请输入索赔单号',
                valueName: 'claimNumber',
            },
            {
                type: 'select',
                placeholder: '请选择回访状态',
                valueName: 'orderReturnVisitStatus',
                optionList: () => (
                    <>
                        <Option value={0} key={0}>
                            未回访
                        </Option>
                        <Option value={1} key={1}>
                            已回访
                        </Option>
                    </>
                ),
            },
            {
                type: 'RangePicker',
                valueName: ['startTime', 'endTime'],
                span: 16,
                xxl: 8,
            },
            {
                type: 'RangePicker',
                valueName: ['orderCompletedTimeStart', 'orderCompletedTimeEnd'],
                placeholder: ['完成时间', '完成时间'],
                span: 16,
                xxl: 8,
            },
            {
                type: 'RangePicker',
                valueName: ['orderSettleTimeStart', 'orderSettleTimeEnd'],
                placeholder: ['结算时间', '结算时间'],
                span: 16,
                xxl: 8,
            }
        ],
    }

    const [reviewInfo, setReviewInfo] = useState({
        visible: false,
        orderId: '',
    })
    const PrintMenu = () => {
        return (
            <Menu>
                <Menu.Item key="2">
                    <span onClick={() => handlePrint(selectInfo, setPrintVisible)}>财务打印</span>
                </Menu.Item>
                <Menu.Item key="3">
                    <span onClick={() => handleSettleScaniaPrint(selectInfo, setPrintSettleScaniaVisible)}>结算打印</span>
                </Menu.Item>
                <Menu.Item key="0">
                    <span onClick={() => snkyPrint(selectInfo, setPrintSnkyVisible,1)}>工单打印</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => snkyPrint(selectInfo, setPrintSnkyVisible,0)}>工单无单价打印</span>
                </Menu.Item>
            </Menu>
        )
    }
    //列表button
    const topButtons = [
        butResourceApply('orderLook') && {
            click: () => orderHandle('look', state.activeMenu, { selectInfo, dispatch, detailsPage, setPrintSettleScaniaVisible, setPrintVisible, dictObj }),
            content: '查看',
        },
        butResourceApply('completeOrderEdit') && {
            click: () => orderHandle('edit', state.activeMenu, { selectInfo, dispatch, detailsPage, setPrintVisible, dictObj }),
            content: '编辑',
        },
        butResourceApply('orderExport') && {
            click: () => exportOrder(columns, searchValue, state.activeMenu, rowSelection),
            content: '导出',
        },
        butResourceApply('orderExport') && {
            click: () => exportOrderDetailed(columns, searchValue, state.activeMenu, rowSelection),
            content: '导出明细',
        },
        // butResourceApply('orderPoint') && {
        //     click: () => handlePrint(selectInfo, setPrintVisible),
        //     content: '打印',
        // },
        // {
        //     click: () => handleSettleScaniaPrint(selectInfo, setPrintSettleScaniaVisible),
        //     content: '结算打印',
        // },
        // {
        //     click: () => snkyPrint(selectInfo, setPrintSnkyVisible),
        //     content: '工单打印',
        // },
        {
            dropdown:true,
            ButtonType: <Dropdown overlay={PrintMenu}>
            <Button shape="round" className="but-left">
            打印
                <DownOutlined />
            </Button>
        </Dropdown>
        },
        {
            click: () => openViewReport(),
            content: '查看报告',
        },
        butResourceApply('orderSynchronousVehicleManagOffice') && {
            click: () => handleBjsgl(),
            content: '同步车管所',
        },
        butResourceApply('orderAuditRecords') && {
            click: () => reviewRecord(selectInfo, setReviewInfo),
            content: '审核记录',
        },
        butResourceApply('printFeiESmallTicket') && {
            click: () => handlePrintSmallTicket('wechart'),
            content: '打印飞鹅小票(微信)',
        },
        butResourceApply('printFeiESmallTicket') && {
            click: () => handlePrintSmallTicket('alipay'),
            content: '打印飞鹅小票(支付宝)',
        },
        {
            click: () => {
                setShowSetTableSort(true)
            },
            content: '设置table排序',
        },
    ]
    return (
        <>
            {/* {state.activeMenu === "completeOrder" && ( */}
            {
                <>
                    <TopSearch
                        title="工单管理"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    {columns.length > 0 && (
                        <ListComponent
                            title="已完成工单列表"
                            buttons={topButtons}
                            loading={loading}
                            columns={columns}
                            dataSource={data}
                            pagination={pagination}
                            onTableChange={onTableChange}
                            selectInfo={selectInfo}
                            scroll={{ x: 'max-content' }}
                            setSelectInfo={(obj) => setSelectInfo(obj)}
                            rowSelection={rowSelection}
                            subTitle={subTitle}
                        />
                    )}
                </>
            }
            {printVisible.visible && <ParintMadal visible={printVisible} onClose={(bool) => {setPrintVisible(bool);loadData(pagination.current, searchValue)}} />}
            {printSettleScaniaVisible.visible && <SettleScaniaPrints visible={printSettleScaniaVisible} onClose={(bool) => {setPrintSettleScaniaVisible(bool);loadData(pagination.current, searchValue)}}/>}
            {printSnkyVisible.visible && <SknyPrints visible={printSnkyVisible} onClose={(bool) => {setPrintSnkyVisible(bool);loadData(pagination.current, searchValue)}}  type={printSnkyVisible.type}/>}
            {bjsgl.visible && <VehihcleInfoMadal visible={bjsgl.visible} onClose={(bool) => setBjsgl({ visible: bool })} orderInfo={selectInfo.rowInfo} />}
            {reviewInfo.visible && (
                <ReviewRecord visible={reviewInfo.visible} orderId={reviewInfo.orderId} onClose={() => setReviewInfo({ visible: false, orderId: '' })} />
            )}

            {/* 三包信息 */}
            <Sanbao mode={'edit'} orderId={choosedOrderId} visible={showSanbao} setShowSanbe={setShowSanbao} />
            {/* 日志 */}
            <OrderLogs orderId={choosedOrderId} visible={showLogs} setShowLogs={setShowLogs} />
            {/* 设置排序 */}
            <SetTableSort
                visible={showSetTableSort}
                setShowTableSort={setShowSetTableSort}
                saveKey={saveKey}
                allData={getAllTable({ isOrderReturnVisitStatus: true })}
                handleChangeColumns={initTableColumns}
            />
            {/* 查看报告 */}
            {
                viewReportInfo.visible && <ViewReport visible={viewReportInfo.visible}  orderId={viewReportInfo.orderId} setViewReportInfo={setViewReportInfo}/>
            }
        </>
    )
}
export default OrdersManage
